import React, { useState } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import '../../styles/base.css';

interface Building {
  src: string;
  name: string;
  description: string;
}

const FacilityGridView = () => {
  const buildings: Building[] = [
    { src: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fbuildings%2Fcentral_command_center.png?alt=media&token=d140d7d3-67fe-4d06-9ec3-4b5c0715a0f7', name: 'Central Command Center', description: 'Coordinate personnel across the facility' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fbuildings%2Fanomaly_research_center.png?alt=media&token=da1aadde-3f17-49db-9764-44eb98e8fc8a', name: 'Anomalous Research Center', description: 'Research and experiment with anomalies' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fbuildings%2Ftraining_facility.png?alt=media&token=a149a35a-0182-4ff2-b97f-962362849e2a', name: 'Containment Protocol Training', description: 'Train personnel in SCP containment' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fbuildings%2Fresearch.png?alt=media&token=fe25a022-8e06-4e62-a00b-dafa4c0547bb', name: 'Research Laboratory Center', description: 'Utilize labs for SCP research' },
    { src: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fbuildings%2Fscp.png?alt=media&token=207eb837-e0da-4c3b-be0f-843618f75dad', name: 'SCP Containment Wings', description: 'House and monitor SCP entities' }, 
    { src: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fbuildings%2Fenergy.png?alt=media&token=e41d6ed2-e39e-4eae-9f93-2f3d0e5d3c62', name: 'Anomalous Energy Center', description: 'Manage energy from anomalous sources' }

  ];

  const buildingColors = {
    'Central Command Center': 'linear-gradient(to bottom, #000000 40%, #a6c0fe 150%)',
    'Anomalous Research Center': 'linear-gradient(to bottom, #000000 40%, #f3dd97 150%)',
    'Containment Protocol Training': 'linear-gradient(to bottom, #000000 40%, #4d727e 150%)',
    'Research Laboratory Center': 'linear-gradient(to bottom, #000000 40%, #1f55c2 150%)',
    'SCP Containment Wings': 'linear-gradient(to bottom, #000000 40%, #c74cb9 150%)',
    'Anomalous Energy Center': 'linear-gradient(to bottom, #000000 40%, #4d7e5a 150%)'
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(null);

  const openModal = (building: Building) => {
    setSelectedBuilding(building);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="gridContainer">
      {buildings.map((building, index) => (
        <div
          key={index}
          className="gridItem"
          onClick={() => openModal(building)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              openModal(building);
            }
          }}
          tabIndex={0}
          aria-label={`Open details for ${building.name}`}
          style={{
            background: buildingColors[building.name as keyof typeof buildingColors],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="gradient-overlay"></div>
          <img
            src={building.src}
            alt={building.name}
            style={{
              width: '300px',
              height: '300px',
              objectFit: 'contain',
              margin: 'auto',
              display: 'block',
            }}
          />
          <div className="item-info">
            <Typography
                component="h3"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
                sx={{ textAlign: 'center', fontSize: '1.2rem !important' }}
              >
              {building.name}
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center', fontSize: '0.875rem !important' }}>
              {building.description}
            </Typography>
          </div>
        </div>
      ))}

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalVisible}
        onClose={closeModal}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          {selectedBuilding && (
            <>
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
                sx={{ textAlign: 'center' }}
              >
                {selectedBuilding.name}
              </Typography>
              <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center' }}>
                {selectedBuilding.description}
              </Typography>
            </>
          )}
        </Sheet>
      </Modal>
    </div>
  );
};

export default FacilityGridView;
