import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#bb9df7',
      light: '#c8acef',
      dark: '#9e51ea',
    },
    secondary: {
      main: '#7031a0',
      light: '#7e49a4',
      dark: '#6a20a0',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#b3b3b3',
    },
    background: {
      default: '#1B1B1B',
      paper: '#323232',
    },
    error: {
      main: '#ea1f75',
      light: '#e25c94',
      dark: '#e60c67',
    },
    warning: {
      main: '#FCA311',
      light: '#FFBA08',
      dark: '#C76B00',
    },
  },
  typography: {
    fontFamily: 'Space Grotesk, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 16,
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
    h1: {
      fontSize: '5rem',
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500
    }
  },
};

const theme = createTheme(themeOptions);
export default theme;
