import apiUrl from '../config';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface FetchOptions {
    method: HttpMethod;
    body?: any;
    headers?: HeadersInit;
}

export async function fetchWithToken(url: string, options: FetchOptions, token?: string): Promise<any> {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    const urlObject = new URL(url);
    url = urlObject.pathname.replace('/api', ''); 
  }

  const headers = new Headers(options.headers || {});
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  headers.set('Content-Type', 'application/json');

  try {
    const response = await fetch(`${apiUrl}/api/${url}`, {
      ...options,
      headers,
    });

    const contentType = response.headers.get('Content-Type');
    let data = null;

    if (contentType && contentType.includes('application/json')) {
      data = await response.json();
    }

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Response error text:', errorText);
      throw new Error(`Error ${response.status}: ${response.statusText} - ${errorText}`);
    }

    return {
      ok: response.ok,
      status: response.status,
      data: data || response,
    };
  } catch (error) {
    console.error('Fetch error:', error);
    throw new Error('Internal Server Error');
  }
}

export default fetchWithToken;