import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { fetchWithToken } from '../../utils/fetchUtils';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      SCP: Facility Manager {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  const navigate = useNavigate();
  const [signupSuccess, setSignupSuccess] = React.useState(false);
  const [signupError, setSignupError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [usernameError, setUsernameError] = React.useState('');

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if(!passwordRegex.test(password)) {
      setPasswordError('Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character');
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if(!emailRegex.test(email)) {
      setEmailError('Incorrect Email format, please enter a valid email address');
      return false;
    } else {
      setEmailError('');
      return true;
    }
  }

  const validateUsername = (username: string) => {
    if(username.length < 3 || username.length > 16) {
      setUsernameError('Username must be between 3 and 16 characters long');
      return false;
    } else {
      setUsernameError('');
      return true;
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get('password') as string;
    const email = data.get('email') as string;
    const username = data.get('username') as string;
    const isPasswordValid = validatePassword(password);
    const isEmailValid = validateEmail(email);
    const isUsernameValid = validateUsername(username);
    if (!isPasswordValid || !isEmailValid || !isUsernameValid) {
      return;
    }
    const signupData = {
      username,
      email,
      password,
    };
  
    try {
      console.log('Signup data:', JSON.stringify(signupData));
      const response = await fetchWithToken('users/signup', {
          method: 'POST',
          body: JSON.stringify(signupData),
          headers: {
              'Content-Type': 'application/json',
          },
      });
  
      console.log('Response OK:', response.ok);
      console.log('Response status:', response.status);
      console.log('Received response:', response);
  
      if (response.ok) {
          console.log('Signup successful:', response.message);
          localStorage.setItem('token', response.token); 
          setSignupSuccess(true);
          setTimeout(() => {
              navigate('/home');
          }, 2000);
      } else {
          console.log("Signup failed response:", response);
          setSignupError(true);
          setErrorMessage(response.message || 'Signup failed.');
          setTimeout(() => {
              setSignupError(false);
              setErrorMessage('');
          }, 5000);
      }
    } catch (err) {
        console.error('Error during signup:', err);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {signupSuccess && (
        <Alert 
        severity="success" 
        variant="outlined" 
        sx={{
          position: 'absolute',
          top: 10,
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: 0,
          animation: 'fadein 0.5s forwards',
          '@keyframes fadein': {
            '0%': {
              opacity: 0,
              top: 0,
            },
            '100%': {
              opacity: 1,
              top: 10,
            },
          },
        }}>Signup successful! Redirecting you to the dashboard...</Alert>
      )}
      {signupError && (
        <Alert
        severity="error"
        variant="outlined"
        sx={{
          position: 'absolute',
          top: 10,
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: 0,
          animation: 'fadein 0.5s forwards',
          '@keyframes fadein': {
            '0%': {
              opacity: 0,
              top: 0,
            },
            '100%': {
              opacity: 1,
              top: 10,
            },
          },
        }}>Signup failed: {errorMessage}</Alert>
        )}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onBlur={(e) => validateUsername(e.target.value)}
                error={!!usernameError}
                helperText={usernameError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onBlur={(e) => validateEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onBlur={(e) => validatePassword(e.target.value)}
                  error={!!passwordError}
                  helperText={passwordError}
                />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}