import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useUser } from '../context/UserContext';
import { useFacility } from '../context/FacilityContext';
import Button from '@mui/joy/Button';
import FacilityGridView from '../components/FacilityManager/FacilityGridView';
import ResourcesView from '../components/FacilityManager/ResourcesView';
import ResearchView from '../components/FacilityManager/ResearchView';
import OperationsView from '../components/FacilityManager/OperationsView';
import { fetchWithToken } from '../utils/fetchUtils';
import theme from '../joyTheme';
import '../styles/base.css';

export default function FacilityManager() {
  const [view, setView] = useState('resources');
  const { user } = useUser();
  const { facility } = useFacility();
  const [facilityLevel, setFacilityLevel] = useState<number | null>(null);
  const [resources, setResources] = useState<any>(null);

  useEffect(() => {
    const fetchFacilityData = async () => {
      if (user && user.id) {
        try {
          const token = localStorage.getItem('token') || undefined;
          const response = await fetchWithToken(`facility/${user.id}`, { method: 'GET' }, token);

          if (response.ok) {
            const data = response.data;
            setFacilityLevel(data.level);
            setResources(data.resources);
          } else {
            throw new Error('Failed to fetch facility data');
          }
        } catch (error) {
          console.error('Error fetching facility data:', error);
        }
      }
    };

    fetchFacilityData();
  }, [user]);

  const buttonStyle = {
    fontFamily: (theme.typography as any).fontFamily,
  };

  return (
    <div className="background">
      <Navbar username={user ? user.username : 'Guest'} userId={user ? user.id : 0} />
      <div className="page-header-text">
        {facility ? (
          <>
            <h1>{facility.name ? facility.name.toUpperCase() : 'FACILITY'}</h1>
            {facilityLevel !== null && (
              <div>Level: {facilityLevel}</div>
            )}
          </>
        ) : (
          <h1>Loading facility...</h1>
        )}
      </div>
      <div className="facility-manager-navigation-header">
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('resources')}>RESOURCES</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('facility-management')}>FACILITY MANAGEMENT</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('facility-overview')}>FACILITY OVERVIEW</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('research')}>RESEARCH</Button>
        <Button variant="outlined" className="btn" sx={buttonStyle} onClick={() => setView('operations')}>OPERATIONS</Button>
      </div>
      <div className="facility-manager-container">
        <div className="panel">
          {view === 'facility-management' && <FacilityGridView />}
          {view === 'resources' && <ResourcesView facilityId={user ? user.id : 0} />}
          {view === 'research' && <ResearchView />}
          {view === 'operations' && <OperationsView/>}
        </div>
      </div>
    </div>
  );
}