import React from 'react';
import { styled } from '@mui/system';
import '../styles/base.css';

interface SCPData {
    id: number;
    base_number: string;
    scp_number: string;
    name: string;
    motto: string;
    classification: string;
    image_url: string;
    rarity?: string;
    wear?: string;
    is_variant?: boolean;
}

interface SCPCardProps {
    scp: SCPData;
    onSelect: (scp: SCPData) => void;
    isModalView?: boolean;
    isOwned?: boolean;
}

const getColorByRarity = (rarity: string | undefined): string => {
    switch (rarity) {
        case 'uncommon': return '#00FF00';
        case 'rare': return '#0000FF';
        case 'epic': return '#800080';
        case 'legendary': return '#FFD700';
        default: return 'transparent';
    }
}

const CardContainer = styled('div')<{ rarity?: string, isModalView?: boolean }>`
    position: relative;
    width: ${props => props.isModalView ? '300px' : '200px'};
    height: ${props => props.isModalView ? '450px' : '300px'};
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    border: ${props => !props.isModalView && 'none'};
    box-shadow: ${props => (!props.isModalView && props.rarity) ? `0 0 10px 2px ${getColorByRarity(props.rarity)}` : 'none'};
    &:hover {
        transform: ${props => !props.isModalView && 'scale(1.05)'};
    }
`;

const CardImage = styled('img')`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const WearOverlay = styled('div')<{ wear?: string }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => {
        switch (props.wear) {
            case 'Mint': return 'rgba(255, 255, 255, 0.1)';
            case 'Polished': return 'rgba(255, 255, 255, 0.2)';
            case 'Weathered': return 'rgba(255, 255, 255, 0.4)';
            case 'Battered': return 'rgba(255, 255, 255, 0.6)';
            case 'Ruined': return 'rgba(255, 255, 255, 0.8)';
            default: return 'none';
        }
    }};
    pointer-events: none;
`;

const SCPCard: React.FC<SCPCardProps> = ({ scp, onSelect, isModalView, isOwned }) => {
    const { name, image_url, rarity, wear } = scp;

    return (
        <CardContainer rarity={rarity} onClick={() => onSelect(scp)} isModalView={isModalView} className={`scp-card ${!isOwned ? `grayscale`: ''}`}>
            {image_url && <CardImage src={image_url} alt={name} />}
            <WearOverlay wear={wear} />
        </CardContainer>
    );
};

export default SCPCard;