import { CssVarsThemeOptions, extendTheme } from '@mui/joy/styles';

const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: '#bb9df7',
          solidColor: '#fff',
          solidHoverBg: '#c8acef',
          solidActiveBg: '#9e51ea',
          outlinedBg: 'transparent',
          outlinedBorder: '#bb9df7',
          outlinedColor: '#bb9df7',
          outlinedHoverBg: 'rgba(188, 157, 247, 0.3)',
          outlinedActiveBg: 'rgba(188, 157, 247, 0.2)'
        },
        neutral: {
          solidBg: '#7031a0',
          solidColor: '#fff',
          solidHoverBg: '#7e49a4',
          solidActiveBg: '#6a20a0',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          solidBg: '#bb9df7',
          solidColor: '#fff',
          solidHoverBg: '#c8acef',
          solidActiveBg: '#9e51ea',
          outlinedBg: 'transparent',
          outlinedBorder: '#bb9df7',
          outlinedColor: '#bb9df7',
          outlinedHoverBg: 'rgba(188, 157, 247, 0.3)',
          outlinedActiveBg: 'rgba(188, 157, 247, 0.2)'
        },
        neutral: {
          solidBg: '#7031a0',
          solidColor: '#fff',
          solidHoverBg: '#7e49a4',
          solidActiveBg: '#6a20a0',
        },
      },
    }
  },
  typography: {
    fontFamily: 'Space Grotesk, sans-serif',
    display1: { 
      fontSize: '5rem',
      fontWeight: 700,
    },
    display2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
  } as CssVarsThemeOptions['typography'], 
});

export default joyTheme;
