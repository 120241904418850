import React from 'react';
import Navbar from '../components/Navbar';
import { useUser } from '../context/UserContext';
import '../styles/base.css';

export default function Market() {
    const { user } = useUser();

    return (
        <div className="background">
            <Navbar username={user ? user.username : 'Guest'} userId={user ? user.id : 0}/>
        </div>
    );
}