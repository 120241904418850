import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useUser } from '../../context/UserContext';

const LogoutButton = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('facility');

    setUser(null);

    navigate('/login');
  };

  return (
    <Button onClick={handleLogout} color="inherit">Logout</Button>
  );
};

export default LogoutButton;
