import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import * as THREE from 'three';
import FOG from 'vanta/dist/vanta.fog.min';
import Typed from 'typed.js';

export default function LandingPage() {
    const navigate = useNavigate();
    const vantaRef = useRef<HTMLDivElement>(null);
    const vantaEffect = useRef<any>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    const onButtonClick = (toNav: string) => {
        navigate(`/${toNav}`);
    };

    useEffect(() => {
        if (THREE && FOG) {
            try {
                if (vantaRef.current) {
                    vantaEffect.current = FOG({
                        el: vantaRef.current,
                        mouseControls: true,
                        touchControls: true,
                        gyroControls: false,
                        minHeight: 200.00,
                        minWidth: 200.00,
                        highlightColor: 0xffffff,
                        midtoneColor: 0x0,
                        lowlightColor: 0x0,
                        baseColor: 0xc0c0c,
                        blurFactor: 0.10,
                        speed: 2.00,
                        zoom: 2.00
                    });
                }
            } catch (error) {
                console.error('Error initializing Vanta.js:', error);
            }
        }
        return () => {
            if (vantaEffect.current) vantaEffect.current.destroy();
        };
    }, []);

    useEffect(() => {
        const options = {
            strings: ["Secure. Contain. Protect."],
            typeSpeed: 100,
            backSpeed: 50,
            startDelay: 1000,
            backDelay: 2000,
            loop: true,
            showCursor: false,
            autoInsertCss: true,
        };
        const typed = new Typed('.typewriter', options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ display: 'none' }}>
                <defs>
                    <filter id="distortion-filter">
                        <feTurbulence type="fractalNoise" baseFrequency="0.05" numOctaves="4" result="turbulence" />
                        <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="30" />
                        <feColorMatrix type="hueRotate" values="90" result="colorShift" />
                        <feComponentTransfer>
                            <feFuncR type="linear" slope="1.5" />
                            <feFuncG type="linear" slope="1.5" />
                            <feFuncB type="linear" slope="1.5" />
                        </feComponentTransfer>
                    </filter>
                </defs>
            </svg>

            <Box className="wrapper" ref={vantaRef}>
                <CssBaseline />
                <Box className="hero">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/logos%2Fscplogo.png?alt=media&token=9b1a5f42-7511-44ca-af95-527acc528a0c"
                        alt="SCP Logo"
                        height="500px"
                        loading="lazy"
                        className={`scp-logo ${imageLoaded ? 'distortion' : 'loading'}`}
                        onLoad={() => setImageLoaded(true)}
                    />
                    <Box className="typewriter-container">
                        <Typography variant="h4" className="typewriter"></Typography>
                    </Box>
                    <Typography variant="h1" className="title">
                        SCP: Facility Manager
                    </Typography>
                    <Box className="button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => onButtonClick("login")}
                        >
                            Login
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={() => onButtonClick("signup")}
                        >
                            Register
                        </Button>
                    </Box>
                </Box>

                <Box className="divider-landing-page"></Box>

                <Box className="section">
                    <Container maxWidth="md">
                        <Typography variant="h2" gutterBottom>
                            About the Game
                        </Typography>
                        <Typography variant="body1">
                            SCP: Facility Manager is an immersive game where you manage and secure a facility housing anomalous entities.
                        </Typography>
                    </Container>
                </Box>

                <Box className="divider-landing-page"></Box>

                <Box className="section">
                    <Container maxWidth="md">
                        <Typography variant="h2" gutterBottom>
                            Features
                        </Typography>
                        <Typography variant="body1">
                            Explore a variety of features including research, containment, and facility upgrades to enhance your management experience.
                        </Typography>
                    </Container>
                </Box>

                <Box className="divider-landing-page"></Box>

                <Box className="section">
                    <Container maxWidth="md">
                        <Typography variant="h2" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body1">
                            Reach out to our team for support and inquiries.
                        </Typography>
                    </Container>
                </Box>
            </Box>
        </>
    );
}