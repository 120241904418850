import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { fetchWithToken } from '../utils/fetchUtils';

interface ProtectedRouteProps {
    children: React.ReactNode;
    redirectTo: string;
}

interface ErrorState {
    message: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, redirectTo }) => {
    const { user, isInitialLoading } = useUser();
    const [facilityExists, setFacilityExists] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<ErrorState | null>(null);
  
    useEffect(() => {
        async function checkFacility() {
          if (!user?.id) {
            setIsLoading(false);
            setFacilityExists(null);
            return;
          }
      
          try {
            const token = localStorage.getItem('token');
            if (!token) {
              throw new Error('Token not found');
            }
      
            const response = await fetchWithToken(`users/user/${user.id}/facility-exists`, { method: 'GET' }, token);
      
            if (response.ok) {
              setFacilityExists(response.data.facilityExists);
            } else {
              throw new Error('Failed to check facility existence');
            }
          } catch (error) {
            const err = error as Error;
            setError({ message: err.message });
          } finally {
            setIsLoading(false);
          }
        }
      
        checkFacility();
      }, [user?.id]);
  
    if (isLoading || isInitialLoading) {
        return <div>Loading...</div>;
    }

    if (!user && !isInitialLoading) {
        return <Navigate to="/login" />;
    }

    if (facilityExists === false) {
        return <Navigate to={redirectTo} />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;