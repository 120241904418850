import React, { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useUser } from '../context/UserContext';
import Navbar from '../components/Navbar';
import SCPCard from '../components/SCPCard';
import '../styles/base.css';
import { Typography } from '@mui/material';
import { fetchWithToken } from '../utils/fetchUtils';

interface SCPData {
    id: number;
    base_number: string;
    scp_number: string;
    name: string;
    motto: string;
    classification: string;
    image_url: string;
    rarity?: string;
    wear?: string;
    is_variant?: boolean;
}

export default function SCPCollection() {
    const { user } = useUser();
    const [scpData, setScpData] = useState<SCPData[]>([]);
    const [userCards, setUserCards] = useState<SCPData[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showUnowned, setShowUnowned] = useState(false);
    const [showBaseCards, setShowBaseCards] = useState(true);
    const [showVariantCards, setShowVariantCards] = useState(true);
    const [selectedClassifications, setSelectedClassifications] = useState<string[]>([]);
    const [selectedRarities, setSelectedRarities] = useState<string[]>([]);
    const [selectedWearLevels, setSelectedWearLevels] = useState<string[]>([]);
    const [selectedSCP, setSelectedSCP] = useState<SCPData | null>(null);
    const [selectedSCPVariants, setSelectedSCPVariants] = useState<SCPData[]>([]);
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

    useEffect(() => {
        const fetchScpData = async () => {
            try {
                const response = await fetchWithToken('scps', { method: 'GET' });
    
                if (response.ok) {
                    const scpDataWithVariant = response.data.map((scp: SCPData) => ({
                        ...scp,
                        is_variant: !!scp.rarity
                    }));
                    setScpData(scpDataWithVariant);
                } else {
                    console.error('Error fetching SCP data:', response);
                }
            } catch (error) {
                console.error("Error fetching SCP data:", error);
            }
        };
    
        fetchScpData();
    }, []);
    
    useEffect(() => {
        const fetchUserCards = async () => {
            try {
                const token = localStorage.getItem('token') || undefined;
                if (token && user?.id) {
                    const response = await fetchWithToken(`users/${user.id}/cards`, { method: 'GET' }, token);
    
                    if (response.ok) {
                        setUserCards(response.data);
                    } else {
                        console.error('Error fetching user cards:', response);
                    }
                }
            } catch (error) {
                console.error("Error fetching user cards:", error);
            }
        };
    
        if (user) {
            fetchUserCards();
        }
    }, [user]);

    const isOwned = (scp: SCPData): boolean => {
        return userCards.some(userCard => userCard.id === scp.id);
    };

    const handleSelectSCP = (scp: SCPData) => {
        console.log("Selected SCP:", scp);
        if (scp.is_variant) {
            const variants = userCards.filter(userCard => userCard.base_number === scp.base_number && userCard.is_variant);
            console.log("Variants found:", variants);
            setSelectedSCPVariants(variants);
            setSelectedVariantIndex(0);
            setSelectedSCP(variants[0] || scp);
        } else {
            setSelectedSCPVariants([]);
            setSelectedVariantIndex(0);
            setSelectedSCP(scp);
        }
    };

    const handleNextVariant = () => {
        if (selectedSCPVariants && selectedVariantIndex < selectedSCPVariants.length - 1) {
            setSelectedVariantIndex(prevIndex => {
                const newIndex = prevIndex + 1;
                setSelectedSCP(selectedSCPVariants[newIndex]);
                console.log(`Next Variant: ${newIndex}`);
                return newIndex;
            });
        } else {
            console.log('Next Variant button should be disabled');
        }
    };
    
    const handlePrevVariant = () => {
        if (selectedSCPVariants && selectedVariantIndex > 0) {
            setSelectedVariantIndex(prevIndex => {
                const newIndex = prevIndex - 1;
                setSelectedSCP(selectedSCPVariants[newIndex]);
                console.log(`Previous Variant: ${newIndex}`);
                return newIndex;
            });
        } else {
            console.log('Previous Variant button should be disabled');
        }
    };

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            setSelectedSCP(null);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        setSearchTerm(value || '');
    };

    const handleClassificationChange = (event: React.ChangeEvent<{}>, value: string[] | null) => {
        setSelectedClassifications(value || []);
    };

    const handleRarityChange = (event: React.ChangeEvent<{}>, value: string[] | null) => {
        setSelectedRarities(value || []);
    };

    const handleWearLevelChange = (event: React.ChangeEvent<{}>, value: string[] | null) => {
        setSelectedWearLevels(value || []);
    };

    const filteredSCPs = scpData
        .map(scp => ({
            ...scp,
            isOwned: isOwned(scp),
            count: userCards.filter(userCard => userCard.scp_number === scp.scp_number).length
        })
        )
        .filter(scp => {
            return (
                (!searchTerm || scp.base_number.includes(searchTerm)) &&
                (showUnowned || scp.count > 0) &&
                (showBaseCards && !scp.is_variant || showVariantCards && scp.is_variant) &&
                (selectedClassifications.length === 0 || selectedClassifications.includes(scp.classification)) &&
                (selectedRarities.length === 0 || selectedRarities.includes(scp.rarity || 'null')) &&
                (selectedWearLevels.length === 0 || selectedWearLevels.includes(scp.wear || 'null' ))
            );
        });

        return (
            <div className="background">
                <Navbar username={user ? user.username : 'Guest'} userId={user ? user.id : 0} />
                <div className="page-header-text">
                    <Typography variant="h1">SCP COLLECTION</Typography>
                </div>
                <div className="scp-collection-container">
                    <div className="scp-cards">
                        <div className="filter-settings">
                            <Autocomplete
                                options={Array.from(new Set(scpData.map(scp => scp.base_number)))}
                                renderInput={(params) => <TextField {...params} label="Search SCPs" variant="outlined" />}
                                className="search-bar"
                                onInputChange={(event, value) => setSearchTerm(value || '')}
                            />
                            <div className="filters-container">
                                <FormLabel component="legend">Filters</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={showUnowned} onChange={(e) => setShowUnowned(e.target.checked)} />}
                                        label="Show Unowned"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={showBaseCards} onChange={(e) => setShowBaseCards(e.target.checked)} />}
                                        label="Base Cards"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={showVariantCards} onChange={(e) => setShowVariantCards(e.target.checked)} />}
                                        label="Variant Cards"
                                    />
                                </FormGroup>
                                <Autocomplete
                                    multiple
                                    options={['Safe', 'Euclid', 'Keter', 'Thaumiel']}
                                    renderInput={(params) => <TextField {...params} label="Classification" variant="outlined" />}
                                    value={selectedClassifications}
                                    onChange={(event, value) => setSelectedClassifications(value || [])}
                                    className="filter-autocomplete"
                                />
                                <Autocomplete
                                    multiple
                                    options={['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary']}
                                    renderInput={(params) => <TextField {...params} label="Rarity" variant="outlined" />}
                                    value={selectedRarities}
                                    onChange={(event, value) => setSelectedRarities(value || [])}
                                    className="filter-autocomplete"
                                />
                                <Autocomplete
                                    multiple
                                    options={['Mint', 'Polished', 'Weathered', 'Battered', 'Ruined']}
                                    renderInput={(params) => <TextField {...params} label="Wear Level" variant="outlined" />}
                                    value={selectedWearLevels}
                                    onChange={(event, value) => setSelectedWearLevels(value || [])}
                                    className="filter-autocomplete"
                                />
                            </div>
                        </div>
                        <div className="scp-cards-container">
                            <div className="scp-cards-grid">
                                {filteredSCPs.map((scp, index) => (
                                    <SCPCard 
                                        key={index} 
                                        scp={scp} 
                                        onSelect={handleSelectSCP} 
                                        isOwned={scp.isOwned}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {selectedSCP && (
    <div className="scp-modal-overlay" onClick={handleClose}>
        <div className="scp-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="scp-modal-left">
                <div className="scp-card-with-arrows">
                    <SCPCard 
                        scp={selectedSCPVariants[selectedVariantIndex] || selectedSCP} 
                        onSelect={() => {}} 
                        isModalView 
                        isOwned={isOwned(selectedSCPVariants[selectedVariantIndex] || selectedSCP)}
                    />
                    <div className="scp-modal-navigation-arrows">
                        <Button 
                            onClick={handlePrevVariant}
                            variant="outlined" 
                            disabled={selectedVariantIndex === 0}
                            sx={{
                                color: 'white',
                                borderColor: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                },
                                '&:disabled': {
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                }
                            }}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </Button>
                        <Button 
                            onClick={handleNextVariant}
                            variant="outlined" 
                            disabled={selectedVariantIndex === selectedSCPVariants.length - 1}
                            sx={{
                                color: 'white',
                                borderColor: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                },
                                '&:disabled': {
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                }
                            }}
                        >
                            <ArrowForwardIosRoundedIcon />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="scp-modal-right">
                <Typography variant="h3" className="scp-modal-title">{selectedSCP.name}</Typography>
                <div className="scp-info">
                    <Typography variant="body1" className="scp-motto">{selectedSCP.motto}</Typography>
                    <div className="scp-detail">
                        <Typography variant="h6" component="label">Classification</Typography>
                        <Typography variant="body2">{selectedSCP.classification}</Typography>
                    </div>
                    {selectedSCP.is_variant && (
                        <>
                            <div className="scp-detail">
                                <Typography variant="h6" component="label">Rarity</Typography>
                                <Typography variant="body2">{selectedSCP.rarity ? selectedSCP.rarity.charAt(0).toUpperCase() + selectedSCP.rarity.slice(1).toLowerCase() : 'Common'}</Typography>
                            </div>
                            <div className="scp-detail">
                                <Typography variant="h6" component="label">Wear</Typography>
                                <Typography variant="body2">{selectedSCP.wear}</Typography>
                            </div>
                        </>
                    )}
                    <div className="scp-detail">
                        <Typography variant="h6" component="label">Owned</Typography>
                        <Typography variant="body2">{isOwned(selectedSCP) ? 'Yes' : 'No'}</Typography>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}
            </div>
        );
    }