import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fetchWithToken from '../utils/fetchUtils';

export default function VerifyEmail() {
  const [verificationStatus, setVerificationStatus] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    console.log('Token from URL:', token);

    const verifyEmail = async () => {
      try {
        console.log('Requesting verification at:', `/verify-email?token=${token}`);
        const response = await fetchWithToken(`verify-email?token=${token}`, {
          method: 'GET',
          headers: {},
        });

        console.log('Full Response:', response);

        if (response.ok) {
          console.log('Verification succeeded');
          setVerificationStatus('Email verified successfully!');
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } else {
          console.error('Verification failed:', response);
          setVerificationStatus('Email verification failed: ' + (response.data?.message || 'Unknown error'));
        }
      } catch (error) {
        console.error('Verification failed:', error);
        setVerificationStatus('Email verification failed');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>{verificationStatus}</p>
    </div>
  );
}