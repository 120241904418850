import React, { useState, useEffect } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { fetchWithToken } from '../../utils/fetchUtils';
import '../../styles/base.css';

interface Resource {
  iconSrc: string;
  backgroundSrc: string;
  name: string;
  amount: number;
  description: string;
}

interface ResourcesViewProps {
  facilityId: number;
}

const ResourcesView: React.FC<ResourcesViewProps> = ({ facilityId }) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedResource, setSelectedResource] = useState<Resource | null>(null);

  useEffect(() => {
    const fetchFacilityData = async () => {
      try {
        const response = await fetchWithToken(`facility/${facilityId}`, { method: 'GET' });
        const data = response.data;
        setResources([
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Felectricity.png?alt=media&token=203f306b-bf98-446a-9268-4b26b0a4345a', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Felectricity.jpg?alt=media&token=05af778b-658a-4608-9d84-fe8c17bd25b5', 
              name: 'Electricity', 
              amount: data.electricity, 
              description: 'Electricity is required to power the facility.' 
            },
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Fanomalous_energy.png?alt=media&token=4e3f220a-7ebd-454c-a4e6-bbf1bed846b6', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Fanomalous_energy.jpg?alt=media&token=97bc0635-2ee0-4956-9529-503568b77cc2', 
              name: 'Anomalous Energy', 
              amount: data.anomalous_energy, 
              description: 'Anomalous Energy is generated by certain SCPs.' 
            },
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Fresearch_materials.png?alt=media&token=4066a72f-7315-45e6-a5c4-769ab03b5dea', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Fresearch_materials.png?alt=media&token=c4c317bb-3eb1-4f05-b5c2-d04220c08dcd', 
              name: 'Research Materials', 
              amount: data.research_materials, 
              description: 'Research Materials are used for developing new containment strategies.' 
            },
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Fbioenergy.png?alt=media&token=66e094ba-beda-4628-8004-57530568d505', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Fbioenergy.jpg?alt=media&token=2b1d11ab-45e4-4376-969b-112b66f476ca', 
              name: 'Bioenergy', 
              amount: data.bioenergy, 
              description: 'Bioenergy is extracted from biological SCPs.' 
            },
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Fdark_matter.png?alt=media&token=132befbf-8fc7-487d-8c0e-b483ae8b2641', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Fdark_matter.jpg?alt=media&token=c8ad17de-50be-426a-adf8-7bbc04e3f742', 
              name: 'Dark Matter', 
              amount: data.dark_matter, 
              description: 'Dark Matter is a rare resource used for high-level upgrades.' 
            },
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Fconstruction_units.png?alt=media&token=2e37392b-38c4-4f01-b6a4-2c7124637da0', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Fconstruction_units.jpg?alt=media&token=18387ad3-fa7f-47cc-ba89-0876cf0fdf12', 
              name: 'Construction Units', 
              amount: data.construction_units, 
              description: 'Construction Units are used to build and upgrade facility structures.' 
            },
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Fcredit.png?alt=media&token=25c791e1-4e7c-45b3-a71e-a63e7c1845dd', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Fcredit.jpg?alt=media&token=075f781f-1876-4c9a-87ae-20355ffb55a9', 
              name: 'Foundation Credits', 
              amount: data.foundation_credits, 
              description: 'Foundation Credits are the main currency used for purchases and upgrades.' 
            },
            { 
              iconSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Ficons%2Ftoken.png?alt=media&token=d1899c70-1def-431e-803a-b66ab0ce754c', 
              backgroundSrc: 'https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/facility_manager%2Fresources%2Fbackgrounds%2Ftoken.jpg?alt=media&token=b65cc43d-0fb5-425d-ad29-395eb88311d5', 
              name: 'Foundation Tokens', 
              amount: data.foundation_tokens, 
              description: 'Foundation Tokens are a premium currency for special items and upgrades.' 
            }
          ]);
        } catch (error) {
          console.error('Error fetching facility data:', error);
        }
      };
      fetchFacilityData();
  }, [facilityId]);

  const openModal = (resource: Resource) => {
    setSelectedResource(resource);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="gridContainer">
      {resources.map((resource, index) => (
        <div
          key={index}
          className="gridItem"
          onClick={() => openModal(resource)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              openModal(resource);
            }
          }}
          tabIndex={0}
          aria-label={`Open details for ${resource.name}`}
          style={{ backgroundImage: `url(${resource.backgroundSrc})` }}
        >
          <div className="gradient-overlay"></div>
          <div
            className="icon"
            style={{
              backgroundImage: `url(${resource.iconSrc})`,
              width: "200px",
              height: "200px",
              backgroundSize: 'cover',
              mixBlendMode: 'screen',
              margin: 'auto',
              display: 'block'
            }}
          ></div>
          <div className="item-info">
            <Typography level="h3" textColor="#fff">
              {resource.name}
            </Typography>
            <Typography level="body-md" textColor="neutral.300">
              {resource.amount}
            </Typography>
          </div>
        </div>
      ))}

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalVisible}
        onClose={closeModal}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          {selectedResource && (
            <>
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
                sx={{ textAlign: 'center' }}
              >
                {selectedResource.name}
              </Typography>
              <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center' }}>
                {selectedResource.description}
              </Typography>
              <Typography id="modal-amount" textColor="text.secondary" sx={{ textAlign: 'center', marginTop: '10px' }}>
                Amount: {selectedResource.amount}
              </Typography>
            </>
          )}
        </Sheet>
      </Modal>
    </div>
  );
};

export default ResourcesView;
