import React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { Link } from 'react-router-dom';
import LogoutButton from './Login/Logout';
import '../styles/base.css';

interface NavbarProps {
  username: string;
  userId: number;
}

const Navbar: React.FC<NavbarProps> = ({ username, userId }) => {
  return (
    <nav className="navbar">
      <div className="navbar-links">
        <Link to="/home">HOME</Link>
        <Link to="/scp-collection">SCP COLLECTION</Link>
        <Link to="/facility-manager">FACILITY MANAGER</Link>
        <Link to="/market">MARKET</Link>
      </div>
      <div className="navbar-section">
        <Dropdown>
          <MenuButton className="username">{username || 'User'}</MenuButton>
          <Menu>
            <MenuItem>
              <LogoutButton />
            </MenuItem>
          </Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default Navbar;