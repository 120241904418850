import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, { ReactFlowProvider, Position, Node, Edge, useReactFlow } from 'react-flow-renderer';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import { useTheme } from '@mui/joy';
import '../../styles/base.css';

type ViewType = 'containment_protocol_training_facility' | 'anomalous_energy_plant' | 'scp_containment_center' | 'research_laboratory' | 'anomaly_research_center' | 'central_command_center';

const initialEdges: { [key in ViewType]: Edge[] } = {
  containment_protocol_training_facility: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  anomalous_energy_plant: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  scp_containment_center: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  research_laboratory: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  anomaly_research_center: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
  central_command_center: [
    { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: '#000' } },
    { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: '#000' } },
  ],
};

const calculateInitialPositions = (viewportWidth: number, viewportHeight: number): { [key in ViewType]: Node[] } => {
  const centerX = viewportWidth / 2;
  const centerY = viewportHeight / 2;

  return {
    research_laboratory: [
      { id: '1', type: 'input', data: { label: 'Basic Research Techniques' }, position: { x: centerX - 75, y: centerY - 200 }, style: { backgroundColor: '#f3f4f6' }, sourcePosition: Position.Bottom },
      { id: '2', data: { label: 'Enhanced Microscopes' }, position: { x: centerX - 225, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
      { id: '3', data: { label: 'Basic Containment Analysis' }, position: { x: centerX + 50, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
      { id: '4', data: { label: 'Advanced Bio-Chemical Instruments' }, position: { x: centerX + 50, y: centerY + 100 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
    ],
    containment_protocol_training_facility: [
      { id: '1', type: 'input', data: { label: 'Surveillance Training Program' }, position: { x: centerX - 75, y: centerY - 200 }, style: { backgroundColor: '#f3f4f6' }, sourcePosition: Position.Bottom },
      { id: '2', data: { label: 'Basic Containment Drills' }, position: { x: centerX - 225, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
      { id: '3', data: { label: 'Emergency Response Drill Zone' }, position: { x: centerX + 50, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
    ],
    anomalous_energy_plant: [
      { id: '1', type: 'input', data: { label: 'Enhanced EM Harvesters' }, position: { x: centerX - 75, y: centerY - 200 }, style: { backgroundColor: '#f3f4f6' }, sourcePosition: Position.Bottom },
      { id: '2', data: { label: 'Improved Thermal Converters' }, position: { x: centerX - 225, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
    ],
    scp_containment_center: [
      { id: '1', type: 'input', data: { label: 'Reinforced Safe Containment' }, position: { x: centerX - 75, y: centerY - 200 }, style: { backgroundColor: '#f3f4f6' }, sourcePosition: Position.Bottom },
      { id: '2', data: { label: 'Enhanced Euclid Monitoring' }, position: { x: centerX - 225, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
      { id: '3', data: { label: 'Advanced Keter Containment' }, position: { x: centerX + 50, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
    ],
    anomaly_research_center: [
      { id: '1', type: 'input', data: { label: 'Basic Anomaly Analysis' }, position: { x: centerX - 75, y: centerY - 200 }, style: { backgroundColor: '#f3f4f6' }, sourcePosition: Position.Bottom },
      { id: '2', data: { label: 'Anomalous Energy Containment' }, position: { x: centerX - 225, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
    ],
    central_command_center: [
      { id: '1', type: 'input', data: { label: 'Enhanced Surveillance Integration' }, position: { x: centerX - 75, y: centerY - 200 }, style: { backgroundColor: '#f3f4f6' }, sourcePosition: Position.Bottom },
      { id: '2', data: { label: 'Basic Command Coordination' }, position: { x: centerX - 225, y: centerY - 50 }, style: { backgroundColor: '#f3f4f6' }, targetPosition: Position.Top, sourcePosition: Position.Bottom },
    ],
  };
};
   
const createButtonNodes = (viewportWidth: number): Node[] => [
  { 
    id: 'btn-1', 
    type: 'button', 
    data: { label: 'Research Laboratory' }, 
    position: { x: viewportWidth / 2 - 600, y: 75 }, 
  },
  { 
    id: 'btn-2', 
    type: 'button', 
    data: { label: 'Containment Protocol Training Facility' }, 
    position: { x: viewportWidth / 2 - 600, y: 25 }, 
  },
  { 
    id: 'btn-3', 
    type: 'button', 
    data: { label: 'Anomalous Energy Plant' }, 
    position: { x: viewportWidth / 2 - 180, y: 25 }, 
  },
  { 
    id: 'btn-4', 
    type: 'button', 
    data: { label: 'SCP Containment Center' }, 
    position: { x: viewportWidth / 2 + 240, y: 25 }, 
  },
  { 
    id: 'btn-5', 
    type: 'button', 
    data: { label: 'Anomaly Research Center' }, 
    position: { x: viewportWidth / 2 - 180, y: 75 }, 
  },
  { 
    id: 'btn-6', 
    type: 'button', 
    data: { label: 'Central Command Center' }, 
    position: { x: viewportWidth / 2 + 240, y: 75 }, 
  },
];

const calculateTranslateExtent = (viewportWidth: number, viewportHeight: number): [[number, number], [number, number]] => {
  const padding = 200;
  return [
    [0 - padding, 0 - padding],
    [viewportWidth + padding, viewportHeight + padding],
  ];
};

const ButtonNode = ({ data }: any) => {
  const theme = useTheme();

  return (
    <Button
      variant='soft'
      color='primary'
      sx={{
        backgroundColor: theme.colorSchemes.dark.palette.primary.solidBg,
        color: '#fff',
        padding: '8px 10px',
        borderRadius: '5px',  
        fontSize: '1.1rem',
        fontFamily: (theme.typography as any).fontFamily,
        textAlign: 'center',
        minHeight: '40px',
        minWidth: '400px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.colorSchemes.dark.palette.primary.solidHoverBg,
          color: '#fff',
        },
      }}
    >
      {data.label}
    </Button>
  );
};

const nodeTypes = { button: ButtonNode };

const ResearchView = () => {
  const [currentView, setCurrentView] = useState<ViewType>('containment_protocol_training_facility');
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [translateExtent, setTranslateExtent] = useState<[[number, number], [number, number]]>([[0, 0], [1600, 1200]]);
  const reactFlowInstance = useReactFlow();

  const setInitialNodesAndEdges = useCallback(() => {
    const viewport = document.querySelector('.react-flow__viewport');
    if (viewport) {
      const viewportRect = viewport.getBoundingClientRect();
      const calculatedNodes = calculateInitialPositions(viewportRect.width, viewportRect.height);
      const buttonNodes = createButtonNodes(viewportRect.width);
      setNodes([...calculatedNodes[currentView], ...buttonNodes]);
      setEdges(initialEdges[currentView]);
      setTranslateExtent(calculateTranslateExtent(viewportRect.width, viewportRect.height));
    }
  }, [currentView]);

  useEffect(() => {
    setInitialNodesAndEdges();
    reactFlowInstance.fitView({ padding: 0.1 });
  }, [currentView, reactFlowInstance, setInitialNodesAndEdges]);

  const onNodeClick = (event: React.MouseEvent, node: Node) => {
    if (node.type === 'button') {
      switch(node.id) {
        case 'btn-1':
          setCurrentView('research_laboratory');
          break;
        case 'btn-2':
          setCurrentView('containment_protocol_training_facility');
          break;
        case 'btn-3':
          setCurrentView('anomalous_energy_plant');
          break;
        case 'btn-4':
          setCurrentView('scp_containment_center');
          break;
        case 'btn-5':
          setCurrentView('anomaly_research_center');
          break;
        case 'btn-6':
          setCurrentView('central_command_center');
          break;
        default:
          setCurrentView('containment_protocol_training_facility');
      }
    } else {
      setSelectedNode(node);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedNode(null);
  };

  return (
    <ReactFlowProvider>
      <div style={{ width: '100%', height: '90vh' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodeClick={onNodeClick}
          preventScrolling={true}
          zoomOnScroll={false}
          zoomOnPinch={false}
          panOnScroll={false}
          panOnDrag={true}
          translateExtent={translateExtent}
          style={{ backgroundColor: '#282c34' }}
          nodeTypes={nodeTypes}
          nodesDraggable={false}
          nodesConnectable={false}
        />
        
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={modalOpen}
          onClose={closeModal}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            {selectedNode && (
              <>
                <Typography
                  component="h2"
                  id="modal-title"
                  level="h4"
                  textColor="inherit"
                  fontWeight="lg"
                  mb={1}
                  sx={{ textAlign: 'center' }}
                >
                  {selectedNode.data.label}
                </Typography>
                <Typography id="modal-desc" textColor="text.tertiary" sx={{ textAlign: 'center' }}>
                  Details about the {selectedNode.data.label} will go here.
                </Typography>
                <Button onClick={() => { /* Allocate resources or other action */ }}>
                  Allocate Resources
                </Button>
              </>
            )}
          </Sheet>
        </Modal>
      </div>
    </ReactFlowProvider>
  );
};

export default ResearchView;