import { fetchWithToken } from '../utils/fetchUtils';

interface Facility {
  user_id: number;
  name: string;
  level: number;
  alert_level: string;
}

interface Resources {
  electricity: number;
  anomalousEnergy: number;
  researchMaterials: number;
  constructionPower: number;
  bioenergy: number;
  darkMatter: number;
}

export interface FacilityData {
  facility: Facility;
  resources: Resources;
}

// Function to fetch facility data
export const fetchFacilityData = async (facilityId: number): Promise<Facility> => {
  try {
    const response = await fetchWithToken(`facility/${facilityId}`, {
      method: 'GET',
    });

    // Assuming fetchWithToken returns an object with { ok, status, data } structure
    if (!response.ok) {
      throw new Error(`Failed to fetch facility data: ${response.status}`);
    }

    return response.data; // Assuming response.data contains the facility object
  } catch (error) {
    console.error('Failed to fetch facility data:', error);
    throw new Error('Failed to fetch facility data');
  }
};

// Function to create a facility
export const createFacility = async (facilityData: FacilityData) => {
  try {
    const response = await fetchWithToken('facility', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(facilityData),
    });

    if (!response.ok) {
      const responseData = await response.json();
      throw new Error(`Failed to create facility: ${responseData.message}`);
    }

    return response.data; // Assuming response.data contains the created facility object
  } catch (error) {
    console.error('Error in createFacility:', error);
    throw error;
  }
};