import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import muiTheme from './muiTheme';
import joyTheme from './joyTheme';

const materialTheme = materialExtendTheme(muiTheme);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
    <JoyCssVarsProvider theme={joyTheme}>
      <CssBaseline enableColorScheme />
      <App />
    </JoyCssVarsProvider>
  </MaterialCssVarsProvider>
);

reportWebVitals();
