import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { FacilityProvider } from './context/FacilityContext';
import { ReactFlowProvider } from 'react-flow-renderer';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SCPCollection from './pages/SCPCollection';
import Market from './pages/Market';
import FacilityManager from './pages/FacilityManager';
import FacilitySetup from './pages/FacilitySetup';
import ProtectedRoute from './components/ProtectedRoute';
import VerifyEmail from './components/VerifyEmail';
import './App.css';

const ProtectedRoutes = ({ redirectTo }: { redirectTo: string }) => (
  <ProtectedRoute redirectTo={redirectTo}>
    <Outlet />
  </ProtectedRoute>
);

const App: React.FC = () => {
  return (
    <UserProvider>
      <FacilityProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> 
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route element={<ProtectedRoutes redirectTo="/facility-setup" />}>
              <Route path="home" element={<Home />} />
              <Route path="scp-collection" element={<SCPCollection />} />
              <Route path="market" element={<Market />} />
              <Route path="facility-manager" element={<ReactFlowProvider><FacilityManager /></ReactFlowProvider>} />
            </Route>
            <Route path="/facility-setup" element={<FacilitySetup />} />
          </Routes>
        </Router>
      </FacilityProvider>
    </UserProvider>
  );
}

export default App;