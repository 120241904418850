import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { fetchWithToken } from '../utils/fetchUtils';

interface User {
  id: number;
  username: string;
  email: string;
  facilityId: number | null;
}

interface UserContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loginUser: (userData: User) => void;
  setUserFacilityId: (facilityId: number | null) => void;
  isInitialLoading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    const localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser));
    }

    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetchWithToken('users/user', { method: 'GET' }, token);

          if (response.ok) {
            const userData = response.data;
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData));
          } else {
            throw new Error('Failed to fetch user data');
          }
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          setUser(null);
        } finally {
          setIsInitialLoading(false);
        }
      } else {
        setIsInitialLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const loginUser = (userData: User) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    if (userData.facilityId) {
      localStorage.setItem('facilityId', userData.facilityId.toString());
    }
  };

  const setUserFacilityId = (facilityId: number | null) => {
    if (user) {
      const updatedUser = { ...user, facilityId };
      setUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, loginUser, setUserFacilityId, isInitialLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};