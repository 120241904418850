import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { fetchWithToken } from '../utils/fetchUtils';

const FacilitySetup = () => {
    const [facilityName, setFacilityName] = useState('');
    const [level] = useState(0);
    const [alert_level, setAlertLevel] = useState('Low');
    const navigate = useNavigate();
    const { user } = useUser();

    console.log('User:', user?.id + ' ' + user?.facilityId);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        if (!facilityName || level === undefined || !alert_level || !user?.id) {
            console.error("Validation error: ", {facilityName, level, alert_level, user_id: user?.id});
            alert("Please fill all required fields correctly.");
            return;
        }
    
        if (!user || typeof user.id !== 'number') {
            console.error("User ID is not available or not a number:", user);
            alert("User information is incomplete. Please log in again.");
            return;
        }
    
        const facilityData = {
            facility: {
                user_id: user.id,
                name: facilityName,
                level,
                alert_level,
            },
            resources: {
                electricity: 0,
                anomalousEnergy: 0,
                researchMaterials: 0,
                constructionPower: 0,
                bioenergy: 0,
                darkMatter: 0,
            }
        };
        console.log('Facility Data:', facilityData);
        try {
            const token = localStorage.getItem('token') || undefined;
            const responseData = await fetchWithToken('facility', {
                method: 'POST',
                body: JSON.stringify(facilityData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }, token);
    
            console.log('Server response:', responseData);
            navigate('/home');
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };      

    return (
        <div className="facility-setup">
            <h1>Setup Your Facility</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Facility Name:
                    <input
                        type="text"
                        value={facilityName}
                        onChange={(e) => setFacilityName(e.target.value)}
                    />
                </label>
                <label>
                    Alert Level:
                    <select value={alert_level} onChange={(e) => setAlertLevel(e.target.value)}>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </select>
                </label>
                <button type="submit">Create Facility</button>
            </form>
        </div>
    );
};

export default FacilitySetup;
