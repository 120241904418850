import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { fetchFacilityData } from '../services/facilityService';
import { useUser } from './UserContext';

interface Facility {
  user_id: number;
  name: string;
  level: number;
  alert_level: string;
}

interface Resources {
  electricity: number;
  anomalousEnergy: number;
  researchMaterials: number;
  constructionPower: number;
  bioenergy: number;
  darkMatter: number;
}

interface FacilityContextType {
  facility: Facility | null;
  resources: Resources | null;
  setFacility: React.Dispatch<React.SetStateAction<Facility | null>>;
  setResources: React.Dispatch<React.SetStateAction<Resources | null>>;
  updateFacility: (facilityData: Facility) => void;
  updateResources: (resourcesData: Resources) => void;
}

const FacilityContext = createContext<FacilityContextType | undefined>(undefined);

interface FacilityProviderProps {
  children: ReactNode;
}

export const FacilityProvider = ({ children }: FacilityProviderProps) => {
  const [facility, setFacility] = useState<Facility | null>(null);
  const [resources, setResources] = useState<Resources | null>(null);
  const { user } = useUser();

  useEffect(() => {
    const fetchAndSetFacility = async () => {
      const storedFacility = localStorage.getItem('facility');
  
      if (storedFacility) {
        try {
          const parsedFacility = JSON.parse(storedFacility);
          console.log('Loaded facility from localStorage:', parsedFacility);
          setFacility(parsedFacility);
        } catch (error) {
          console.error('Error parsing facility from localStorage:', error);
        }
      } else if (user?.id) {
        try {
          const facilityData = await fetchFacilityData(user.id);
          console.log('Fetched facility data from backend:', facilityData);
          setFacility(facilityData);
          localStorage.setItem('facility', JSON.stringify(facilityData));
        } catch (error) {
          console.error('Failed to fetch facility data:', error);
        }
      }
    };
  
    if (user?.id) {
      fetchAndSetFacility();
    }
  
    return () => {
      setFacility(null);
      setResources(null);
    };
  }, [user?.id]);

  const updateFacility = (facilityData: Facility) => {
    setFacility(facilityData);
    localStorage.setItem('facility', JSON.stringify(facilityData));
  };

  const updateResources = (resourcesData: Resources) => {
    setResources(resourcesData);
    // Optionally save resources in localStorage or other persistent storage
  };

  return (
    <FacilityContext.Provider value={{ facility, setFacility, resources, setResources, updateFacility, updateResources }}>
      {children}
    </FacilityContext.Provider>
  );
};

export const useFacility = () => {
  const context = useContext(FacilityContext);
  if (!context) {
    throw new Error('useFacility must be used within a FacilityProvider');
  }
  return context;
};