import React, { useEffect, useState } from 'react';

interface GaugeProps {
  progress: number;
}

const Gauge = ({ progress }: GaugeProps) => {
  const radius = 168;
  const strokeWidth = radius * 0.2;
  const innerRadius = radius - strokeWidth;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * 0.75;
  const dashArray = `${arc} ${circumference}`;
  const transform = `rotate(135, ${radius}, ${radius})`;

  const [animatedProgress, setAnimatedProgress] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => setAnimatedProgress(progress), 100);
    return () => clearTimeout(timeout);
  }, [progress]);

  const offset = arc - (animatedProgress / 100) * arc;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <defs>
        <linearGradient id="grad" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor="#b19cd9" stopOpacity="1" />
          <stop offset="50%" stopColor="#a37ccc" stopOpacity="1" />
          <stop offset="100%" stopColor="#9966cc" stopOpacity="1" />
        </linearGradient>
      </defs>
  
      {/* Background arc */}
      <circle
        className="gauge_base"
        cx={radius}
        cy={radius}
        fill="transparent"
        r={innerRadius}
        stroke="#ddd"
        strokeDasharray={dashArray}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        transform={transform}
      />
  
      {/* Progress arc */}
      <circle
        className="gauge_percent"
        cx={radius}
        cy={radius}
        fill="transparent"
        r={innerRadius}
        stroke="url(#grad)"
        strokeDasharray={dashArray}
        strokeDashoffset={offset}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        style={{
          transition: 'stroke-dashoffset 0.85s ease-out',
        }}
        transform={transform}
      />
  
      {/* Percentage text */}
      <text
        x={radius}
        y={radius + strokeWidth / 2}
        textAnchor="middle"
        fontSize={radius * 0.40}
        fill="#ffffff"
      >
        {progress}%
      </text>
    </svg>
  );
};

export default Gauge;